import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess
} from "../actions/Auth";

import DataService from 'services/DataService'

export function* signIn() {
    yield takeEvery(SIGNIN, function* ({payload}) {
		const {domainName, username, password} = payload;
        const user = yield call(DataService.login, domainName, username, password);
        if (user !== null && user !== undefined && user.session !== null && user.session !== undefined) {
            localStorage.setItem(AUTH_TOKEN, user.session);
            yield put(authenticated(user.session, user.isAdmin, user.username, user.config));
        } else {
			yield put(showAuthMessage(user));
		}
	});
}

export function* signOut() {
    yield takeEvery(SIGNOUT, function* (token) {
        const signOutUser = yield call(DataService.signOut, token);
        if (signOutUser === 'OK') {
            localStorage.removeItem(AUTH_TOKEN);
            yield put(signOutSuccess());
        } else {
            yield put(showAuthMessage(signOutUser));
        }
	});
}

export function* signUp() {
    yield takeEvery(SIGNUP, function* ({payload}) {
		const {domainName, username, password, applicationType, numberOfUsers} = payload;
        const user = yield call(DataService.signUp, domainName, username, password, applicationType, numberOfUsers);
        if (user !== null && user !== undefined && user.session !== null && user.session !== undefined) {
            localStorage.setItem(AUTH_TOKEN, user.session);
            yield put(signUpSuccess(user.session, user.username));
        } else {
			yield put(showAuthMessage(user));
		}
	}
	);
}

export default function* rootSaga() {
  yield all([
		fork(signIn),
		fork(signOut),
		fork(signUp)
  ]);
}
