import {reactLocalStorage} from 'reactjs-localstorage';
import { notification } from 'antd';

export async function pmPost(url, paramObj) {
    notification.close("error");
    const version = 20;
    let baseUrl = '';
    if (process.env.NODE_ENV === 'production') {
        if (window.location.host === 'stage.pmgara.com') {
            baseUrl = 'https://api-stage.pmgara.com';
        } else {
            baseUrl = 'https://api.phanmemgara.com';
        }
    } else {
        baseUrl = 'http://localhost:8080'
    }
    let clientId = reactLocalStorage.get('clientId');
    if (clientId === null || clientId === undefined) {
        clientId = new Date().getTime();
        reactLocalStorage.set('clientId', clientId);
    }
    const requestId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
    try {
        notification.info({
            key: "loading",
            message: "Đang tải ...",
            duration: 0
        });
        const response = await fetch(
            baseUrl + url + '?currentSession=' + pmGetCurrentSession() + '&clientId=' + clientId + '&requestId=' + requestId,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(paramObj),
            });
        const data = await response.json();
        notification.close("loading");
        if (data.status === 'SUCCESS') {
            if (version < data.version) {
                _showAlert('Cập nhật phiên bản mới', 'Bạn cần refresh trình duyệt để cập nhật phiên bản quản lý gara mới!');
                return null;
            }
            if (data.globalErrors.length === 0 && data.fieldErrors.length === 0) {
                return data.data
            } else if (data.globalErrors.length > 0) {
                const unauthenticatedErr = data.globalErrors.find(e => e.code === 10002);
                const unauthorizedErr = data.globalErrors.find(e => e.code === 10007);
                const domainLockedErr = data.globalErrors.find(e => e.code === 10014);
                if (unauthenticatedErr !== null && unauthenticatedErr !== undefined) {
                    _showAlert('Đăng nhập lại', 'Bạn cần thoát ra và đăng nhập lại!');
                } else if (unauthorizedErr !== null && unauthorizedErr !== undefined) {
                    _showAlert('Không cho phép', 'Người dùng hiện tại không được thực hiện chức năng này!');
                } else if (domainLockedErr !== null && domainLockedErr !== undefined) {
                    _showAlert('Kích hoạt tài khoản', 'Tài khoản công ty cần được kích hoạt, xin liên hệ 0853531368!');
                } else {
                    _showAlert('Lỗi', data.globalErrors[0].errorMessage);
                }
            } else if (data.fieldErrors.length > 0) {
                _showAlert('Lỗi', data.fieldErrors[0].errorMessage);
            }
            return data;
        } else {
            _showAlert('Lỗi hệ thống', JSON.stringify(data));
            return data;
        }
    } catch (err) {
        notification.close("loading");
        _showAlert('Lỗi hệ thống', err);
        return null;
    }
}

export function pmFormatCurrency(num) {
    if (num !== null && num !== undefined) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
        return '';
    }
}

export function pmMoveNext(event, elRef, checkEnter) {
    if (checkEnter === false || event.key === 'Enter') {
        elRef.current.focus();
        if (typeof elRef.current.select !== "undefined") {
            elRef.current.select();
        }
        if (event !== null && event !== undefined) {
            event.preventDefault();
        }
    }
}

export function pmSetCurrentSession(session) {
    reactLocalStorage.set('currentSession', session);
}

export function pmGetCurrentSession() {
    return reactLocalStorage.get('currentSession');
}

export function pmEncodePara(value) {
    return btoa(encodeURI(value));
}

export function pmDecodePara(value) {
    return decodeURI(atob(value));
}

export function pmAlert(msg) {
    notification.info({key: "info", message: msg, duration: 0});
}

function _showAlert(title, msg) {
    notification.error({
        key: "error",
        message: title,
        description: msg
    });
}

export function pmFormatDate(date) {
    if (date === null || date === undefined) {
        return null;
    }
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
}

export function formatContactInfo(val) {
    if (val !== null && val !== undefined) {
        return val.split('+++').map(line => `<span>${line}</span>`).join('<br>');
    } else {
        return '';
    }
}

export async function askUserPermission() {
    return await Notification.requestPermission();
}

export async function createNotificationSubscription() {
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    // subscribe and return the subscription
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'BGbFJAyBI9AzjOy9WMS3uPeMHf4tb1HKIHuTcaN06VL8RfnbWh13m1yl5Iywcl2m_SF15FQJd5qJAvwd_uULkPI'
    });
}

export function displayStatus(statusMapper, status) {
    if (statusMapper.hasOwnProperty(status)) {
        return statusMapper[status];
    } else {
        return 'Không rõ';
    }
}