import {
    PlusOutlined,
    CarOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    DashboardOutlined,
    BarChartOutlined,
    SearchOutlined,
    SettingOutlined,
    TeamOutlined,
    CustomerServiceOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const mainNavTree = [{
    key: 'sidenav',
    title: 'sidenav.menu',
    breadcrumb: false,
    submenu: [
        {
            key: 'sidenav.dashboard',
            path: `${APP_PREFIX_PATH}`,
            title: 'sidenav.dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
        {
            key: 'btnNewRo',
            id: 'btnNewRo',
            path: `${APP_PREFIX_PATH}/pos-ticket-form`,
            title: 'sidenav.new_ro',
            icon: PlusOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'btnWip',
            id: 'btnWip',
            path: `${APP_PREFIX_PATH}/wip`,
            title: 'sidenav.wip',
            icon: CarOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'btnRoSearch',
            id: 'btnRoSearch',
            path: `${APP_PREFIX_PATH}/pos`,
            title: 'sidenav.ro_search',
            icon: SearchOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'btnPurchase',
            id: 'btnPurchase',
            path: `${APP_PREFIX_PATH}/purchase`,
            title: 'sidenav.purchase',
            icon: ShopOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'btnProduct',
            id: 'btnProduct',
            path: `${APP_PREFIX_PATH}/admin-product`,
            title: 'sidenav.product',
            icon: ShoppingCartOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
        {
            key: 'sidenav.customer',
            path: `${APP_PREFIX_PATH}/admin-customer`,
            title: 'sidenav.customer',
            icon: CustomerServiceOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
        {
            key: 'sidenav.report',
            title: 'sidenav.report',
            icon: BarChartOutlined,
            breadcrumb: false,
            isAdmin: true,
            submenu: [
                {
                    key: 'sidenav.report.profit',
                    path: `${APP_PREFIX_PATH}/admin-profit`,
                    title: 'sidenav.report.profit',
                    breadcrumb: false,
                    submenu: []
                },
                {
                    key: 'sidenav.report.customer_income',
                    path: `${APP_PREFIX_PATH}/admin-income-by-customer`,
                    title: 'sidenav.report.customer_income',
                    breadcrumb: false,
                    submenu: []
                },
                {
                    key: 'sidenav.report.vehicle_income',
                    path: `${APP_PREFIX_PATH}/admin-income-by-car`,
                    title: 'sidenav.report.vehicle_income',
                    breadcrumb: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'sidenav.user',
            id: 'btnUser',
            path: `${APP_PREFIX_PATH}/admin-user`,
            title: 'sidenav.user',
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
        {
            key: 'sidenav.setting',
            path: `${APP_PREFIX_PATH}/admin-setting`,
            title: 'sidenav.setting',
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
    ]
 }]

const navigationConfig = [
    ...mainNavTree
]

export default navigationConfig;