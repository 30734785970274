import React, { useEffect } from 'react';
import {Switch, Route, withRouter, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { pmPost, pmGetCurrentSession } from '../util/pmUtils';
import { APP_PREFIX_PATH, APP_ADMIN_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { authenticated, signOutSuccess } from 'redux/actions/Auth';

function RouteInterceptor({ children, loggedIn, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                loggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: AUTH_PREFIX_PATH,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export const Views = (props) => {
    const { locale, token, isAdmin, authenticated, signOutSuccess, location } = props;
    const currentAppLocale = AppLocale[locale];

    useEffect(() => {
        pmPost('/pub-check-session', {currentSession : pmGetCurrentSession()})
        .then((data) => {
            if (data !== null && data !== undefined &&
                (data.isAdmin !== null && data.isAdmin !== undefined)) {
                authenticated(pmGetCurrentSession(), data.isAdmin, data.username, data.config)
            } else {
                signOutSuccess();
            }
        });
    }, [authenticated, signOutSuccess]);

    return(
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={`${APP_PREFIX_PATH}/wip`} />
                    </Route>
                    <Route path={AUTH_PREFIX_PATH}>
                        <AuthLayout />
                    </Route>
                    <RouteInterceptor path={APP_ADMIN_PREFIX_PATH} loggedIn={isAdmin}>
                        <AppLayout />
                    </RouteInterceptor>
                    <RouteInterceptor path={APP_PREFIX_PATH} loggedIn={token}>
                        <AppLayout location={location} />
                    </RouteInterceptor>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
    )
}

const mapStateToProps = ({ theme, auth }) => {
    const { locale } =  theme;
    const { token, isAdmin } = auth;
    return { locale, token, isAdmin }
};

export default withRouter(connect(mapStateToProps, {authenticated, signOutSuccess})(Views));