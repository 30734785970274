import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  SettingOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut, showLoading } from 'redux/actions/Auth';

const menuItem = [
    {
		title: "Đổi mật khẩu",
		icon: SettingOutlined,
		path: "/app/pos-change-pass"
    }
]

export const NavProfile = ({signOut, showLoading, token, username}) => {
    const onSignOut = () => {
        showLoading()
        signOut(token);
    };
    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-body">
                <Menu>
                    {menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <a href={el.path}>
                                    <Icon className="mr-3" type={el.icon} />
                                    <span className="font-weight-normal">{el.title}</span>
                                </a>
                            </Menu.Item>
                        );
                    })}
                    <Menu.Item key={menuItem.legth + 1} onClick={onSignOut}>
                        <span>
                            <LogoutOutlined className="mr-3"/>
                            <span className="font-weight-normal">Thoát</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item>
                    <Avatar style={{backgroundColor: '#00a2ae', verticalAlign: 'middle'}}>{(username !== null && username !== undefined) ? username.substring(0,5) : ''}</Avatar>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

const mapStateToProps = ({auth}) => {
	const {token, username} = auth;
  	return {token, username}
}

export default connect(mapStateToProps, {signOut, showLoading})(NavProfile)